import Button from "components/Button";
import { Helmet } from "react-helmet";

import PageTitle from "components/PageTitle";
import { basics } from "config";


const Zajimavosti = () => {
  return (
    <article className="w-full">
      <PageTitle>Co podniknout ve Zlíně?</PageTitle>

      <Helmet>
        <title>Co podniknout ve Zlíně | {basics.appTitle}</title>
        <meta property="og:title" content="Zajímavosti Zlína" />
      </Helmet>

      <div className="content-block">
      
        <p>Pokud byste si našli čas na procházku městem, můžeme doporučit několik zajímavých míst k navštívení či prohlédnutí.
</p>

<ul>
<li>
<b>21. budova bývalého Svitu aka <a href="https://zlinskykraj.cz/bauv-mrakodrap-sidlo-zlinskeho-kraje/">Baťův mrakodrap</a> aka sídlo Zlínského kraje</b> 
<br/>Nachází se na dohled z Interhotelu a pár minut chůze skrze podchod a bývalou vstupní bránu do továrny. Můžete se v něm projet patnáctipatrovým paternosterem či rychlovýtahem a zamířit na střešní terasu. V druhém patře je k nahlédnutí Baťova pojízdná výtahová kancelář. Budova by měla být otevřena do 21. hodiny.<br/>&nbsp;
</li>

<li>
<b>Muzeum jihovýchodní Moravy ve <a href="https://www.muzeum-zlin.cz/14-budova/">14. budově</a> bývalého Svitu</b>
<br/>Nachází se přesně o jednu budovu na východ a tři budovy na sever od “jednadvacítky”. Je zde především stálá expozice Princip Baťa: Dnes fantazie, zítra skutečnost ve 3. podlaží. Na ploše téměř 1500 m2 jsou sdružena neotřelým způsobem hned tři témata. Je zde podrobně zachycena historie obuvnické firmy Baťa, a to včetně muzea obuvi nebo výrobní linky. Tuto část doplňuje expozice věnovaná zlínskému filmu od jeho baťovských počátků přes slavná léta Karla Zemana nebo Hermíny Týrlové, až po počátek 21. století. Poslední část je věnována cestám legendární dvojice Hanzelka a Zikmund, stejně jako dalším významným cestovatelům. Součástí budovy je také Krajská galerie výtvarného umění.<br/>&nbsp;
</li>

<li>
<b><a href="https://pamatnikbata.eu/">Památník</a> Tomáše Bati</b>
<br/>Nachází se na stejném kopci jako Interhotel Zlín, na horním konci travnatého náměstí T. G. Masaryka. Funkcionalistická budova, která je poctou zakladateli obuvnického koncernu. Zářící vitrína z betonu, skla a oceli. Nebo chcete-li, moderní parafráze na stavby vrcholné gotiky. I tak lze uvést radikální dílo architekta Františka Lýdie Gahury.<br/>&nbsp;
</li>

<li>
<b>Zlínský <a href="https://www.zlinskyzamek.cz/">zámek</a></b>
<br/>Také na dohled z hotelu, vzdálený jen přes dvě křižovatky. Zámek ve Zlíně je čtyřkřídlý zámek s vnitřním nádvořím uprostřed sadu Svobody v centru Zlína. Zámek byl původně gotická tvrz, která byla přestavěna ve druhé polovině 16. století do renesanční podoby.<br/>&nbsp;
</li>

<li>
<b>Filmové <a href="https://www.zlinfilmoffice.cz/lokace/katalog-a-poptavka/film-ateliery/">ateliéry na Kudlově</a></b>
<br/>Desítky let chátrající Velký ateliér ve Zlíně, přezdívaný „kudlovská stodola“ se podařilo roku 2022 částečně opravit. Ateliéry vznikly ve 30. letech minulého století k natáčení reklamních filmů společnosti Baťa. Později tam vznikly stovky snímků, působila tam Hermína Týrlová či Karel Zeman. Vznikly zde známé snímky, jako například Cesta do pravěku, Ferda Mravenec nebo Requiem pro panenku.<br/>&nbsp;
</li>

<li>
<b><a href="https://www.kudyznudy.cz/aktivity/stezka-zdravi-tlusta-hora-prostor-pro-sport-a-re/">Stezka zdraví</a> Tlustá hora</b>
<br/>Tip pro milovníky procházek a čerstvého lesního vzduchu.<br/>&nbsp;
</li>

<li>
<b>Zábavní <a href="https://www.toboga.cz/zlin">park Galaxie</a></b>
<br/>Vyžití pro rodiče s menšími dětmi.<br/>&nbsp;
</li>

<li>
<b>… a v neposlední řadě naše oficiální parties!</b>
</li>

</ul>

      </div>


      {/*
      <Button
              className="md:text-xl btn--to-blue-300 mt-8 mb-8"
              bodyClassName="whitespace-no-wrap"
              color="black"
              icon="ico--chevron-right"
              href={mapa_pdf}
              target="_blank"
            >
              Mapa restaurací v okolí konání workshopů
      </Button>
*/}

    </article>
  );
};

export default Zajimavosti;
