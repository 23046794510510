import React from "react";
import { Helmet } from "react-helmet";
import { MapContainer, TileLayer } from "react-leaflet";

import MarkerItem from "components/MarkerItem";
import PageTitle from "components/PageTitle";
import { basics, map } from "config";

import VenueCard from "components/VenueCard";
import { venues } from "config";


const Transportation = () => {
  const accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

  return (
    <article className="space-y-8 w-full">
      <PageTitle>Doprava</PageTitle>
      <Helmet>
        <title>Doprava | {basics.appTitle}</title>
        <meta property="og:title" content="Mapa" />
      </Helmet>

      <div className="content-block">
        <h2>Kde zasedání CF a doprovodný program probíhá?</h2>
      </div>

      <div className="grid grid-cols-4 gap-4">
        <MapContainer
          center={[map.center.lat, map.center.lon]}
          zoom={map.center.zoom}
          scrollWheelZoom={true}
          style={{ height: "600px", width: "100%" }}
          className="col-span-4"
        >
          <TileLayer
            url={`https://api.mapbox.com/styles/v1/xaralis/ck4oblwty0fgk1fjzxmqow2r5/tiles/256/{z}/{x}/{y}@2x?access_token=${accessToken}`}
            attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
          />
          {map.markers.map((marker) => (
            <MarkerItem
              key={marker.title}
              type={marker.type}
              coords={marker.coords}
              desc={
                <>
                  <strong>{marker.title}</strong>
                  <div>{marker.desc}</div>
                </>
              }
            />
          ))}
        </MapContainer>
      </div>

{/*
      <div className="grid md:grid-cols-3 gap-4 md:gap-4 content-block">
        {venues.map((venueItem) => (
          <VenueCard
            street={venueItem.street}
            city={venueItem.city}
            gps={venueItem.gps}
            href={venueItem.href}
            title_short={venueItem.title_short}
            key={venueItem.title_short}
          />
        ))}
      </div>
*/}

<div className="grid md:grid-cols-3 gap-4 md:gap-4 content-block">
<VenueCard
    street="náměstí Práce 2512"
    city="760 01 Zlín"
    gps="49.221116N,17.661250E"
    href="https://interhotelzlin.cz/konference/"
    title_short="Interhotel Zlín"
  />
  </div>

      <div className="content-block">
        <h2>Jak se dostanu do Zlína?</h2>

        <p>Do Zlínského kraje alias na Floridu České republiky se můžete pohodlně dopravit mnoha způsoby. Například autem po dálnici D55 či vlakem s tratí v páteřním koridoru Ostrava - Břeclav. Obojí končí v Otrokovicích, odkud je Zlín co by teniskou dohodil.
</p>

        <h2>Autem (... co takhle si domluvit spolujízdu?)</h2>

        <p>Z většiny území ČR vás navigace nejspíš povede přes zmíněné Otrokovice a dlouhou čtyřproudou avenue až do centra Zlína. Interhotel pak bude jednou z dominant v kopci po pravé straně. Při jízdě ve dvou pruzích mějte strpení s vyhlášenými domorodými řidiči.
</p>
        <p>Parkovat lze pohodlně přímo před hotelem za poplatek 150 Kč na den. Případně na blízkých velkokapacitních parkovištích Březnická, Velké kino a Nad Tržnicí nebo v ulicích s parkovacími automaty <a href="https://www.tszlin.cz/parkovani/">(mapy a ceníky)</a>.</p>
        <p>Parkoviště vyjma hotelového jsou zpoplatněná v pátek do 19. hodiny a v sobotu v čase 8:00 - 13:00.</p>

        <h2>Vlakem / busem / MHD</h2>

        <p>V případě vlaku se předpokládá přestup v Otrokovicích a následný cca třicetiminutový přejezd do Zlína osobákem do stanice Zlín - střed. Při výstupu z vlaku doporučujeme zavřít oči. Zdejší nádraží je jednou z největších ostud města. <br/>Druhou pohodlnou (a <a href="https://www.youtube.com/watch?v=NkSvaWSxcxI">legendární</a>) variantou pro přejezd z Otrokovic do Zlína, do stanice náměstí Práce, je trolejbus.</p> 
        <p>Ve vlacích i trolejbusech na této trase platí jízdenky <a href="https://www.dszo.cz/">DSZO / ZID</a>. <br/>Případně využijte Piráty prosazenou novou aplikaci Fairtiq, která funguje ve všech vozidlech veřejné dopravy a u které nemusíte luštit místní jízdní tarify. Přímo do Zlína pravidelně jezdí také řada autobusových linek z více koutů ČR.
</p>
<p>Po Zlíně pak jezdí opět vlak, trolejbusy a autobusy, ale na stěžejní místa se dá v pohodě za pár minut dojít. Kola Nextbike, bohužel, nejspíš budou v únoru mimo provoz.</p>
        
        <h2>Lodí</h2>

        <p>To neklapne.</p>

        <h2>Letadlem</h2>

        <p>To by mohlo, ale to necháme čistě na vás. Nejbližší letiště Otrokovice, Kunovice a Holešov.</p>
        
      </div>
    </article>
  );
};

export default Transportation;
