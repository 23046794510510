export const basics = {
  appTitle: process.env.REACT_APP_CF_TITLE || "CF 2025",
  appDesc:
    process.env.REACT_APP_CF_DESC ||
    "Oficiální stránka letošního ročníku zasedání Celostátního fóra České pirátské strany, 15. 2. 2025",
};

export const api = {
  baseUrl: process.env.REACT_APP_API_BASE_URL || "https://cf2025.pirati.cz/api",
};

export const appurl = {
  baseUrl: process.env.REACT_APP_ROOT_URL || "https://cf2025.pirati.cz",
};


export const eventHall = {
  text: "Zasedání CF 2025",
  title_short: "Interhotel Zlín",
  startinfo: "Sobota 15. února 9:00",
  endinfo: "Sobota 15. února 18:00",
  title: "Interhotel Zlín",
  desc: "Zasedání celostátního fóra v sobotu 15. 2. 2025 od 9:00",
  coords: { lat: 49.22134512734747, lon: 17.661275926171687 },
  street: "náměstí Práce 2512",
  city: "760 01 Zlín",
  gps: "49.221116N,17.661250E",
  href: "http://interhotelzlin.cz/konference/",
  programlink: "program#sobota 14. 2. 2025",
  infolink: "zasedani",
  type: "event",
  group: "basics",
};

export const workshopVenue = {
  text: "Workshopy",
  title_short: "Café Práh",
  startinfo: "Neděle 14. ledna 09.30",
  endinfo: "Neděle 14. ledna 17.00",
  title: "Café Práh",
  desc: "Workshopy v neděli 14. 1. 2024 od 9.30",
  coords: { lat: 49.1872624, lon: 16.615547 },
  street: "Ve Vaňkovce 462/1",
  city: "60200 Brno - Trnitá",
  gps: "49.1872624N, 16.615547E",
  href: "https://www.cafeprah.cz/",
  programlink: "program#neděle 14. 1. 2024",
  infolink: "workshopy",
  type: "event",
  group: "basics",
};

export const partyVenue = {
  text: "Warm-Up Párty",
  startinfo: "Pátek 12. ledna 21:00",
  endinfo: "Sobota 13. ledna 06.00",
  title_short: "Na Střeláku",
  title: "Na Střeláku",
  desc: "Warm-Up párty v pátek 12. 1. 2024 od 21:00",
  coords: { lat: 49.1920890479743, lon: 16.564827167470355},
  street: "Pisárecká 7",
  city: "Brno (u zastávky MHD Antrophos)",
  gps: "49.1920890479743N, 16.564827167470355E",
  href: "https://www.nastrelaku.cz/contact",
  programlink: "program#pátek 12. 1. 2024",
  infolink: "party",
  type: "event",
  group: "basics",
};

export const partyVenue2 = {
  title_short: "Cross Club",
  street: "Plynární 1096/23",
  city: "Praha 7",
  gps: "50.1082031N, 14.4432717E",
  href: "https://www.crossclub.cz/cs/kavarna/",
};

export const partyVenue3 = {
  title_short: "Masařka Club",
  street: "Thákurova 550/1",
  city: "Praha 6 - Bubeneč",
  gps: "50.1009044N, 14.3868344E",
  href: "https://www.facebook.com/masarka.club",
};

export const railwayStation = {
  title: "Vlakové nádraží",
  desc: "Brno hlavní nádraží",
  coords: { lat: 49.1906243, lon: 16.6128025 },
  type: "train",
  group: "transportation",
};

export const busStation = {
  title: "Autobusové nádraží",
  desc: "Brno, ÚAN Zvonařka",
  coords: { lat: 49.1865117, lon: 16.6190199 },
  type: "train",
  group: "transportation",
};

export const parkingVeveri = {
  title: "Parkoviště Veveří",
  desc: "Parkoviště Veveří nad Björnsonovým sadem s kapacitou 140 míst",
  coords: { lat: 49.2075499, lon:  16.5897507},
  type: "location",
  group: "transportation",
};

export const parkingSumavska = {
  title: "Parkoviště Šumavská",
  desc: "Parkoviště Šumavská i s podzemním parkovištěm u Lidlu",
  coords: { lat: 49.2102435, lon: 16.5911999},
  type: "location",
  group: "transportation",
};

export const parkingGymnasium = {
  title: "Parkoviště Gymnázium",
  desc: "Parkoviště Gymnázium Matyáše Lercha",
  coords: { lat: 49.2073543, lon: 16.5893270},
  type: "location",
  group: "transportation",
};

export const parkingVankovka = {
  title: "Parkoviště Galerie Vaňkovka",
  desc: "Parkování v Galerie Vaňkovka - v neděli je zdarma",
  coords: { lat: 49.1873866, lon: 16.614276},
  type: "location",
  group: "transportation",
};

export const busStopTabor = {
  title: "Zastávka Tábor",
  desc: "Zastávka Tábor",
  coords: { lat:  49.21047610015332, lon: 16.587657771543906},
  type: "train",
  group: "transportation",
};

export const busStopiStation = {
  title: "Zastávka Hlavní nádraží",
  desc: "Zastávka Hlavní nádraží",
  coords: { lat:  49.192206512392154, lon: 16.61391223863288 },
  type: "train",
  group: "transportation",
};

export const map = {
  center: { lat: 49.22134512734747, lon: 17.661275926171687, zoom: 14 },
  markers: [eventHall],
//  markers: [eventHall, partyVenue, workshopVenue, railwayStation, busStation, parkingVeveri, parkingSumavska, parkingGymnasium, parkingVankovka, busStopTabor, busStopiStation],
};

export const venues = [partyVenue, eventHall, workshopVenue];
//venues = [ partyVenue, eventHall, workshopVenue]

export const nav = [
  {
    title: "Zasedání CF",
    link: "/zasedani",
  },
//  {
//      link: "/registrace",
//      title: "Registrace",
//      notonhome: true,
//  },
  {
    link: "/ubytovani",
    title: "Ubytování",
  },
//  {
//    title: "Stravování",
//    link: "/stravovani",
//  },
  {
    link: "/doprava",
    title: "Doprava",
  },
//  {
//    link: "/program",
//    title: "Program",
//  },
  {
    title: "Party",
    link: "/party",
  },
//  {
//    title: "Workshopy",
//    link: "/workshopy",
//  },
// {
//   link: "/mapa",
//    title: "Mapa",
//  },
  {
    link: "/organizatori",
    title: "Organizátoři",
  },
//  {
//    link: "/volba-predsednictva",
//    title: "Volba předsednictva",
//  },
//  {
//    link: "/hendikepovani",
//    title: "CF bez bariér",
//  },
  {
    link: "/deti-a-zabava",
    title: "Děti a zábava",
  },
  {
    link: "/co-podniknout-ve-zline",
    title: "Co podniknout ve Zlíně?",
  },
  {
      link: "/pro-dobrovolniky",
      title: "Dobrovolníci",
  },
];

export const organisers = [
  {
    title: "Organizační tým",
    members: [
      {
        name: "Marek Houser",
        position: "Hlavní organizátor",
        phone: "725611894",
        email: "marek.houser@pirati.cz",
        avatarUrl: "https://a.pirati.cz/piratar/200/marek.houser.jpg",
      },
/*
      {
        name: "Hana Černošová",
        position: "Koordinátorka dobrovolníků",
        phone: "770113645",
        email: "hana.cernosova@pirati.cz",
        avatarUrl:
        "/medailonky/hana-cernosova.jpg",
      },
      {
        name: "Vojtěch Pikal",
        position: "Pořad zasedání",
        email: "vojtech.pikal@pirati.cz",
        phone: "778702714",
        avatarUrl:
          "/medailonky/vojtech-pikal.jpg",
      },
      {
        name: "Tomáš Pastirčák",
        position: "Hlavní předsedající",
        email: "tomas.pastircak@pirati.cz",
        phone: "724692024",
        avatarUrl:
          "/medailonky/tomas-pastircak.jpg",
      },
*/
      {
        name: "Josef Bouše",
        position: "Technické zajištění",
        phone: "775180566",
        email: "josef.bouse@pirati.cz",
        avatarUrl:
          "/medailonky/josef-bouse.jpg",
      },
/*
      {
        name: "Lucie Švehlíková",
        position: "Styk s médii, tisková mluvčí",
        email: "luce.svehlikova@pirati.cz",
        phone: "605263660",
        avatarUrl:
          "/medailonky/lucie-svehlikova.png",
      },
*/
      {
        name: "Linda Kašpárková",
        position: "Koordinátorka činovníků, Stage manager",
        email: "linda.kasparkova@pirati.cz",
        phone: "608360206",
        avatarUrl:
          "https://lide.pirati.cz/media/cache/ef/ad/efad8f93796f4d3b9ee2b33d0f369c12.jpg",
      },
      {
        name: "Libor Adámek",
        position: "Web",
        email: "libor.adamek@pirati.cz",
        avatarUrl: "https://a.pirati.cz/piratar/200/libor.adamek.jpg",
      },
      {
        name: "Andrej Ramašeuski",
        position: "Registrace",
        phone: "605234197",
        email: "andrej.ramaseuski@pirati.cz",
        avatarUrl: "https://a.pirati.cz/piratar/200/andrej.ramaseuski.jpg",
      },
/*
      {
        name: "Jiří Kárský",
        position: "Finance, hospodář",
        email: "jiri.karsky@pirati.cz",
        phone: "+420735586424",
        avatarUrl:
          "/medailonky/unknown-pirate.jpg",
      },
*/
    ],
  },
];

export const accommodation = [
  {
    title: "Interhotel Zlín",
    link: "https://interhotelzlin.cz/",
    email: "rezervace-mk@cvut.cz",
    desc: "Hotel přímo v místě konání CF. Snídaně v ceně pokoje.",
    coords: { lat: 49.22135914217796, lon: 17.661254468499365 },
    distance: 0,
    priceClass: "$$$",
    accessibility: {
      status: false,
      help: "Nemá bezbariérový přístup.",
    },
    variants: [
      {
        bedCount: 1,
        price: 2360,
      },
      {
        bedCount: 2,
        price: 2650,
      },
    ],
  },

  {
    title: "Hotel Saloon",
    link: "https://www.hotelsaloon.cz/",
    email: "",
    desc: "Snídaně v ceně pokoje. Pes po dohodě.",
    coords: { lat: 49.23141363996226, lon: 17.671115910602325 },
    distance: 1600,
    priceClass: "$$",
    accessibility: {
      status: false,
      help: "Nemá bezbariérový přístup.",
    },
    variants: [
       {
        bedCount: 1,
        price: 1250,
      },
      {
        bedCount: 2,
        price: 1520,
      },
    ],
  },

  {
    title: "Hotel Baltaci, U Náhonu",
    link: "https://unahonu.baltaci.cz/",
    email: "",
    desc: "Bez snídaně.",
    coords: { lat: 49.228693727737486, lon: 17.678642446721625 },
    distance: 2200,
    priceClass: "$$",
    accessibility: {
      status: false,
      help: "Nemá bezbariérový přístup.",
    },
    variants: [
      {
        bedCount: 2,
        price: 1900,
      },
    ],
  },

  {
    title: "Penzion ER1",
    link: "https://www.er1.cz/",
    email: "",
    desc: "Snídaně za poplatek.",
    coords: { lat: 49.24835424945655, lon: 17.6896241114999 },
    distance: 5100,
    priceClass: "$",
    accessibility: {
      status: false,
      help: "Nemá bezbariérový přístup.",
    },
    variants: [
      {
        bedCount: 1,
        price: 950,
      },
      {
        bedCount: 2,
        price: 1500,
      },
    ],
  },

  {
    title: "Hotel u Ševce",
    link: "https://www.hotelusevce.cz/cz",
    email: "",
    desc: "Snídaně v ceně pokoje.",
    coords: { lat: 49.22709325944005, lon: 17.671131921338716 },
    distance: 1400,
    priceClass: "$$",
    accessibility: {
      status: false,
      help: "Nemá bezbariérový přístup.",
    },
    variants: [
      {
        bedCount: 2,
        price: 1790,
      },
    ],
  },
  {
    title: "Penzion Era",
    link: "https://www.penzion-era.cz/",
    email: "",
    desc: "Bez snídaně.",
    coords: { lat: 49.20564428308119, lon: 17.682599396193076 },
    distance: 2900,
    priceClass: "$",
    accessibility: {
      status: false,
      help: "Nemá bezbariérový přístup.",
    },
    variants: [
      {
        bedCount: 1,
        price: 600,
      },
      {
        bedCount: 2,
        price: 800,
      },
    ],
  },

  {
    title: "Koliba U Černého medvěda",
    link: "https://koliba-zlin.cz/",
    email: "",
    desc: "Snídaně v ceně pokoje.",
    coords: { lat: 49.216270378261996, lon: 17.657483026567192 },
    distance: 1100,
    priceClass: "$$",
    accessibility: {
      status: false,
      help: "Nemá bezbariérový přístup.",
    },
    variants: [
      {
        bedCount: 1,
        price: 1890,
      },
      {
        bedCount: 2,
        price: 2390,
      },
    ],
  },

  {
    title: "Hotel Tomášov",
    link: "https://www.hotel-tomasov.cz/",
    email: "",
    desc: "Snídaně v ceně pokoje.",
    coords: { lat: 49.21681899784466, lon: 17.663638123738682 },
    distance: 1000,
    priceClass: "$$$",
    accessibility: {
      status: false,
      help: "Nemá bezbariérový přístup.",
    },
    variants: [
      {
        bedCount: 1,
        price: 2100,
      },
      {
        bedCount: 2,
        price: 2500,
      },
    ],
  },

  {
    title: "Penzion Uno",
    link: "https://www.penzionuno.cz/",
    email: "",
    desc: "Bez snídaně v ceně.",
    coords: { lat: 49.235845716318394, lon: 17.682529985915092 },
    distance: 2900,
    priceClass: "$$",
    accessibility: {
      status: false,
      help: "Nemá bezbariérový přístup.",
    },
    variants: [
      {
        bedCount: 1,
        price: 1200,
      },
      {
        bedCount: 2,
        price: 1650,
      },
    ],
  },
];

export const programGeneralSettings = {
  published: true,
};

export const program = {
  negotiation: {
    isUnderway: true,
    forumLink: "https://forum.pirati.cz/viewtopic.php?p=836020#p836020",
  },
  published: true,
  days: [
    {
      title: "pátek 8. 11. 2024",
      hourStart: 18,
      hourEnd: 24,
      // The smallest grid resolution in minutes. Take this into consideration when specifying time values.
      resolution: 5,
      tracks: [
        {
          code: "preparty",
          name: "Warm-up",
          venue: "Cross Club (Plynární 1096/23)",
          colorBg: "blue-300",
          colorFg: "white",
        },
        {
          code: "org",
          name: "Taktická príprava",
          venue: "Pirátské centrum (Na Morání 3 a okolí)",
          colorBg: "cyan-400",
          colorFg: "white",
        },
/*
        {
          code: "preparty-games",
          name: "Deskovky",
          venue: "Rishada (Antonína Slavíka 2)",
          colorBg: "cyan-400",
          colorFg: "white",
        },
*/
      ],
      slots: [
        {
          time: "20:00",
          sessions: [
            {
              track: "preparty",
              timeStart: "20:00",
              timeEnd: "23:59",
              title: "Tuc, tuc, tuc!",
              agenda: [
                "Těšit se můžeš na elektronickou hudbu a pirátské DJS do ranních hodin.",
                "19:30 - 21:00 MAGNETIC VOID (melodie na úvod)",
                "21:00 - 22:30 MARRA (techno)",
                "22:30 - 23:30 IVAN BARTOŠ (psy-trance)",
                "23:30 - 01:00 ZDENEK KEY (psy-trance)",
                "01:00 - 02:30 NEURON (dnb)",
              ]
            },
          ],
        },
        {
          time: "18:00",
          sessions: [
            {
              track: "org",
              timeStart: "18:00",
              timeEnd: "20:00",
              title: "Příprava předsedajících",
              agenda: [
                "Příprava pozic a plán bitvy",
              ]
            },
            {
              track: "org",
              timeStart: "20:30",
              timeEnd: "23:00",
              title: "Pivo a deskovky",
              agenda: [
                "Pivní přístav (Rašínovo nábř. 64)",
                "Pivo",
                "Deskovky",
              ]
            },
          ],
        },
      ],
    },
    {
      title: "sobota 9. 11. 2024",
      hourStart: 6,
      hourEnd: 24,
      // The smallest grid resolution in minutes. Take this into consideration when specifying time values.
      resolution: 5,
      tracks: [
        {
          code: "main",
          name: "Hlavní program",
          venue: "Masarykova kolej Praha",
          colorBg: "blue-300",
          colorFg: "white",
        },
        {
          code: "voting",
          name: "Hlasování",
          venue: "Helios",
          colorBg: "black",
          colorFg: "white",
        },
/*
        {
          code: "alternative",
          name: "Alternativní program",
          venue: "Hlavní program",
          colorBg: "grey-125",
          colorFg: "black",
        },
*/
      ],
      slots: [
        {
          time: "06:00",
          sessions: [
            {
              track: "main",
              timeStart: "06:00",
              timeEnd: "08:00",
              title: "Připrava",
              agenda: [
"Příprava sálu",
"Příchod novináři"
              ],
            },
            {
              track: "main",
              timeStart: "08:00",
              timeEnd: "09:00",
              title: "Otevření sálu",
              agenda: [
"Otevření sálu",
"Zahájení registrace",
"Technický start"
              ],
            },
            {
              track: "main",
              timeStart: "09:00",
              timeEnd: "12:00",
              title: "Dopolední blok",
              agenda: [
"Zahájení",
"Volba předsedy - představení kandidátů a 1. rozprava",
"Volba místopředsedů - představení a 1. rozprava",
"Volba předsedy - 2. rozprava",
              ],
            },
          ],
        },
        {
          time: "12:00",
          sessions: [
            {
              track: "main",
              timeStart: "12:00",
              timeEnd: "13:15",
              title: "Obědová pauza",
              agenda: [
"Podle situace může obědová pauza začít už 11:45",
              ],
            },
          ],
        },
        {
          time: "13:15",
          sessions: [
            {
              track: "main",
              timeStart: "13:15",
              timeEnd: "16:00",
              title: "Odpolední blok",
              agenda: [
"Volba předsedy - vyhlášení, projev",
"Představení organizačních změn",
"Úprava vnitřní organizace (změna stanov I.)",
"Volba místopředsedů - 2. rozprava",
"Odvolávání vedoucích odborů (změna stanov II.)",
              ],
            },
          ],
        },
        {
          time: "16:00",
          sessions: [
            {
              track: "main",
              timeStart: "16:00",
              timeEnd: "18:00",
              title: "Závěrečný blok",
              agenda: [
"Prezentace Cílů České pirátské strany (krátky program)",
"Vystoupení Seniorů na palubě",
"Vystoupení Mladého Pirátstva",
"Představení nového předsednictva",
"Společné foto",
"Všeobecná rozprava",
              ],
            },
          ],
        },
        {
          time: "18:00",
          sessions: [
            {
              track: "main",
              timeStart: "18:00",
              timeEnd: "19:00",
              title: "Úklid",
              agenda: [
"Poslední zhasne"
              ],
            },
          ],
        },
        {
          time: "20:00",
          sessions: [
            {
              track: "main",
              timeStart: "20:00",
              timeEnd: "23:59",
              title: "Afterparty",
              agenda: [
"Těšit se můžete na pirátský jam session! Máš hudební nástroj? Přibal ho na afterparty sebou!"
              ],
            },

          ],
        },
      {
          time: "10:30",
          sessions: [
            {
              track: "voting",
              timeStart: "10:30",
              timeEnd: "11:30",
              title: "Volba předsedy – 1. kolo",
              agenda: [
                "Hlasování - cca 10:30 až 11:30 (1 hodina)",
                "Vyhlášení výsledku - cca 11:35",
              ],
            },
            {
              track: "voting",
              timeStart: "12:00",
              timeEnd: "13:00",
              title: "Volba předsedy – 2. kolo",
              agenda: [
                "Hlasování - cca 12:00 až 13:00 (1 hodina)",
                "Vyhlášení výsledku - cca 13:20",
              ],
            },
            {
              track: "voting",
              timeStart: "13:30",
              timeEnd: "14:30",
              title: "Volba místopředsedů – 1. kolo",
              agenda: [
                "Hlasování - cca 13:30 až 14:30 (1 hodina)",
                "Vyhlášení výsledku - cca 14:40",
              ],
            },
            {
              track: "voting",
              timeStart: "15:40",
              timeEnd: "16:40",
              title: "Volba místopředsedů – 2. kolo a změna stanov I",
              agenda: [
                "Hlasování - cca 15:40 až 16:40 (1 hodina)",
                "Vyhlášení výsledku - cca 16:50",
              ],
            },
            {
              track: "voting",
              timeStart: "16:45",
              timeEnd: "17:45",
              title: "Změna stanov II",
              agenda: [
                "Hlasování - cca 16:45 až 17:45 (1 hodina)",
                "Vyhlášení výsledku - cca 17:45",
              ],
            },
          ],
        },

      ],
    },
  ],
};

export const partyBureauCandidates = [
  {
    postTitle: "Předsednický post",
    candidates: [
      {
        name: "Lukáš Wagenknecht",
        desc: "Lukáš Wagenknecht (* 24. 9. 1978) vystudoval obor Hospodářská politika a správa na Univerzitě Pardubice. Přes 15 let se profesně věnoval internímu auditu, podílel se na odhalení zmanipulovaných miliard v operačním programu Severozápad nebo na auditu výstavby metra A. Interní audit přednáší na Vysoké škole ekonomické v Praze a na Univerzitě Pardubice. Prosazuje myšlenky dobrého vládnutí a legislativu zvyšující transparentnost, aktivně se věnuje ekonomickým tématům, energetice, dopravě a ochraně klimatu. V místě svého působení nejčastěji řešil problémy dopravy, ochrany zeleně nebo developerské kauzy a aktivně podporoval místní spolky a občanská sdružení.",
        img: "lukas-wagenknecht.jpg",
        link: "https://forum.pirati.cz/viewtopic.php?t=67756",
      },
      {
        name: "Zdeněk Hřib",
        desc: "Zdeněk Hřib (* 21. 5. 1981) je od května 2016 pirátským garantem v oblasti zdravotnictví, vystudoval 3. lékařskou fakultu Univerzity Karlovy, dříve byl výzkumníkem v oblasti kvality péče a bezpečnosti pacientů, podílel se na vývoji aplikace eRecept, byl členem správní rady VZP od ledna 2018 do února 2019. Odborně se zaměřuje na zdravotnictví a komunální politiku. Od listopadu 2018 do února 2023 byl primátorem hlavního města Prahy. V této funkci čelil několika globálním krizím (pandemii covidu, ekonomické krizi a počátku války na Ukrajině a s tím spojeného velkého přílivu převážně žen, dětí a seniorů do Prahy z válkou zasažených oblastí). Nyní působí jako první náměstek primátora hlavního města Prahy s odpovědností za dopravu. Dohlíží na výstavbu největších aktuálních investic do rozvoje infrastruktury v Česku – od výstavby Dvoreckého mostu, Metra D, rekonstrukce Barrandovského mostu, přípravy Terminálu Smíchov, nebo přípravy VRT. Bojuje za zvýšení pravomocí obcí a měst tak, aby byla reálně plněna zásada subsidiarity, protože věci veřejné se mají řešit co nejblíže lidem.",
        link: "https://forum.pirati.cz/viewtopic.php?t=67832",
        img: "zdenek-hrib.jpg",
      },
      {
        name: "Zbyněk Miklík",
        desc: "Zbyněk Miklík (* 17. 2. 1975) vystudoval provozně ekonomickou fakultu a fakultu informatiky Mendelovy univerzity v Brně. Před vstupem do politiky působil na pozici obchodního ředitele a věnoval se nástrojům pro elektronické zadávání veřejných zakázek. Od roku 2020 do října 2024 byl náměstkem hejtmana Libereckého kraje pro ekonomiku, majetek, investice, veřejné zakázky a informatiku. Je zastupitelem a předsedou kontrolního výboru města Turnova, kde byl také v letech 2018 až 2022 členem rady města. Mezi jeho priority v politice patří posílení energetické soběstačnosti obnovitelnými zdroji, transparentní a zodpovědné hospodaření, otevřené veřejné zakázky, uplatňování digitálních nástrojů pro efektivní fungování úřadů a podpora sociálního podnikání a místních podnikatelů.",
        link: "https://forum.pirati.cz/viewtopic.php?t=67882",
        img: "zbynek-miklik.jpg",
      },
      {
        name: "Adéla Šípová",
        desc: "Adéla Šípová (* 22. 9. 1980) absolvovala právnickou fakultu Univerzity Karlovy, advokátní zkoušku získala v roce 2013. Od roku 2016 se specializovala na právo veřejného zadávání zakázek mimo jiné v souvislosti s kybernetickými hrozbami. Založila také několik neziskových organizací a iniciovala řadu občanských či kulturních aktivit. Od roku 2020 je pirátskou senátorkou za obvod 30 – Kladno. Řeší agendu sociálních témat, problematiku životního prostředí, transparentního hospodaření státu a otevřenější advokacie. Preferuje podporu běžných občanů před investičními pobídkami zahraničním subjektům, předběžnou opatrnost při rozhodování o zásazích do krajiny před ukvapenými megalomanskými projekty a maximální otevřenost úřadů při hospodaření s veřejnými prostředky.",
        link: "https://forum.pirati.cz/viewtopic.php?t=67774",
        img: "adela-sipova.jpg",
      },
      {
        name: "Štěpán Štrébl",
        desc: "Štěpán Štrébl (* 22. 11. 1990) vystudoval UCLA v USA a Cambridge ve Velké Británii. Pracoval pro PwC nebo pro americký startup Thrasio skupující internetové obchody. Od střední školy podniká, čemuž se nyní věnuje naplno. Vede technologický startup Minefield Navigator, který automatizuje evropskou byrokracii pro firmy prodávající přes internet. Žije střídavě v Las Vegas a v Praze. V letech 2017 a 2018 vedl úspěšné kampaně Pirátů do Sněmovny a do pražského zastupitelstva. Od voleb v roce 2018 do konce roku 2021 byl místostarostou na Praze 3, kde prosadil první Montessori školku nebo první bilingvní třídy v okrsku. Zefektivněním úřadu a automatizací úřednické práce ušetřil radnici 5 milionů korun ročně. Zabývá se rušením byrokracie a nahrazováním úředníků umělou inteligencí.",
        link: "https://forum.pirati.cz/viewtopic.php?t=68064",
        img: "stepan-strebl.jpg",
      },
      {
        name: "David Witosz",
        desc: "David Witosz (* 26. 4. 1981) studoval na institutu Geodézie a Důlního Měřictví na VŠB-TU Ostrava Hornicko-geologické fakulty, obor Inženýrská geodézie. Pracoval jako geodet v privátním sektoru a později pro Správu železniční geodézie Olomouc, kde se podílel na modernizaci železničních koridorů, projektů zejména v infrastruktuře a těžkém průmyslu. Od komunálních voleb v roce 2018 působí jako místostarosta městského obvodu Moravská Ostrava a Přívoz, od roku 2021 je také předsedou komise investiční, architektury a rozvoje města Ostrava. V rámci svěřených úkolů místostarosty si jako priority určil obnovu a revitalizaci bytového a nebytového fondu, lepší kultivaci veřejného prostoru a zlepšování finanční stability Moravské Ostravy a Přívoz na základě diverzifikace příjmů. V Pirátské straně byl zakladatelem týmu Doprava, který jako garant dopravy vedl od roku 2017 do roku 2022, kdy byl vedením strany odvolán. V rámci tohoto působení pod jeho vedením vznikl program pirátské dopravy pro volby do PSP 2017 a 2021, platný dodnes.",
        link: "https://forum.pirati.cz/viewtopic.php?t=67867",
        img: "david-witosz.jpg",
      },



    ],
  },
  {
    postTitle: "Místopředsednický post",
    candidates: [
      {
        name: "Pavel Bulíček",
        desc: "Pavel Bulíček (* 1. 5. 1988) vystudoval Univerzitu Hradec Králové a občanským povoláním je softwarový analytik. V letech 2018 až 2022 byl zastupitelem města Hradec Králové. Od roku 2020 do 2024 zastával funkci prvního náměstka hejtmana Královéhradeckého kraje pro oblast investic, inovací a IT. Zaměřoval se především na rozsáhlé modernizace všech krajských nemocnic, navýšení kapacit domovů pro seniory, zlepšování podmínek pro výuku na krajských středních školách nebo na dopravní infrastrukturu. V oblasti IT se soustředil na rozvoj datového portálu kraje založeného na otevřených datech a rovněž zaváděl chytrá a úsporná řešení pro nakládání s energiemi.",
        link: "https://forum.pirati.cz/viewtopic.php?t=67785",
        img: "pavel-bulicek.jpg",
      },
      {
        name: "Markéta Gregorová",
        desc: "Markéta Gregorová (* 14. 1. 1993) vystudovala obor Mezinárodní vztahy a Evropská studia na Masarykově univerzitě v Brně a následně získala magisterský titul v oborech Veřejná správa a Bezpečnostní studia. Pracovala jako e-commerce specialistka, testerka webových aplikací, marketérka a PR specialistka. V říjnu 2018 se stala zastupitelkou města Brna, tuto funkci složila po získání mandátu europoslankyně v květnu 2019. Tento mandát obhájila i v roce 2024. Je členkou několika výborů, v nichž se věnuje problematice vývozu sledovacích technologií a zbraní, ale také dezinformacím, kyberbezpečnosti a dalším moderním technologiím v oblasti bezpečnosti, digitálním svobodám a soukromí v online prostoru.",
        link: "https://forum.pirati.cz/viewtopic.php?t=67955",
        img: "marketa-gregorova.jpg",
      },
      {
        name: "Martin Šmída",
        link: "https://forum.pirati.cz/viewtopic.php?t=67769",
        desc: "Martin Šmída (* 11. 6. 1985) na VUT v Brně vystudoval manažerskou informatiku, ekonomiku a řízení podniku a realitní inženýrství. Od roku 2011 pracoval jako vedoucí kompostárny, v letech 2014 až 2020 byl zastupitelem obce Majetín a od roku 2020 do října 2024 zastupitelem a radním Olomouckého kraje. V politice se věnuje životnímu prostředí, zemědělství a zejména odpadovému hospodářství. Jeho prioritou je také zvyšování kvality života, hájení svobod člověka a důraz na budoucnost skrze moderní vzdělávání, důstojné pracovní příležitosti a dostupné bydlení. V neposlední řadě prosazuje také podporu venkova a pomoc znevýhodněným regionům.",
        img: "martin-smida.jpg",
      },
      {
        name: "Michal Bláha",
        desc: "Michal Bláha (* 19. 10. 1972) je podnikatel, ředitel neziskové organizace Hlídač státu a propagátor e-gov/chytrého a moderního státu. Zabývá se IT, inovacemi a digitální transformací, od roku 2014 zejména ve státní správě. Poskytuje konzultace státním úřadům, pomáhá s realizací systémových změn státní správy. S Piráty spolupracuje od roku 2016 na strategiích, volebních programech a způsobech digitalizace, pomáhal s projektem Otevřená sněmovna. V letech 2022 až 2024 byl poradcem Ivana Bartoše, aktivně se zúčastnil tvorby Zákona o právo na digitální službu a je iniciátorem vzniku Digitální a informační agentury. Za Piráty působí také ve Správní radě VZP.",
        link: "https://forum.pirati.cz/viewtopic.php?t=67962",
        img: "michal-blaha.jpg",
      },
      {
        name: "Hana Hajnová",
        desc: "Hana Hajnová (* 18. 7. 1985) je srdcařka z Vysočiny se zájmem o inovace, obnovitelnou energii a nové technologie. Vystudovala Fakultu sociálních studií Masarykovy univerzity v Brně, v oboru Evropská studia se zaměřením na regionální politiku. Profesí je projektová manažerka, od psaní a řízení projektů si vyzkoušela konzultování startupů i přípravu regionální inovační strategie. Od roku 2018 působí jako zastupitelka ve městě Telči, v letech 2020 až 2024 byla statutární náměstkyní hejtmana Kraje Vysočina pro oblast regionálního rozvoje. V politice se zabývá energetikou, rozvojem znalostní ekonomiky nebo nastavováním a cílením dotačních programů. Do veřejného prostoru se také snaží dostat více žen, například projektem Ženy Vysočiny.",
        link: "https://forum.pirati.cz/viewtopic.php?t=67953",
        img: "hana-hajnova.jpg",
      },
      {
        name: "Dominika Poživilová Michailidu",
        desc: "Dominika Poživilová Michailidu (* 20. 8. 1980) vystudovala TU Liberec a magisterský titul získala na fakultě umění a designu v Ústí nad Labem. Členkou Pirátů se stala v roce 2012 poté, co se zúčastnila několika demonstrací proti ACTA. Byla lídryní v krajských volbách pro Středočeský kraj v roce 2012 a následně i ve volbách do Poslanecké sněmovny v roce 2013. V současnosti je zastupitelkou v obci Krnsko, členkou krajského expertního týmu pro zemědělství a životní prostředí, místopředsedkyně pirátského místního sdružení Mladá Boleslav a obhajující členka Republikového předsednictva. Účastní se resortních týmů Zemědělství a Životní prostředí.",
        link: "https://forum.pirati.cz/viewtopic.php?t=67803",
        img: "dominika-michailidu.jpg",
      },
      {
        name: "Jana Skopalíková",
        desc: "Jana Skopalíková (* 27. 2. 1993) vystudovala na Přírodovědecké fakultě Univerzity Karlovy v Praze bakalářský obor Molekulární biologie a biochemie organismů a magisterský obor Botanika se zaměřením na cévnaté rostliny. V letech 2020 až 2024 působila jako radní pro životní prostředí a zemědělství ve Středočeském kraji. Angažuje se v krajském mediálním odboru a krajském expertním týmu, zabývá se tématy životního prostředí a zemědělství – udržitelností, snižováním dopadů klimatické změny nebo cirkulární ekonomikou. Mimo to ji zajímá i sociální oblast, duševní zdraví nebo téma veřejné dopravy.",
        link: "https://forum.pirati.cz/viewtopic.php?t=68008",
        img: "jana-skopalikova.jpg",
      },
      {
        name: "Tomáš Vymazal",
        desc: "Tomáš Vymazal (* 11. 05. 1990) je odborník na konopnou legislativu. Členem strany je od roku 2010. Profesionálně se věnuje poradenství v oblasti zacházení s návykovými látkami. Od roku 2022 vede Asociaci bezpečného konopí, kde se věnuje připomínkování legislativních návrhů a lobbování. V letech 2017 až 2021 byl poslancem Poslanecké sněmovny PČR a od počátku mandátu byla jeho prioritou legislativa upravující nakládání s konopím. Připravil návrh zákona o regulaci samopěstování psychoaktivního konopí pro osobní potřebu a bojoval za dostupnost léčebného konopí v českých lékárnách. Je spoluautorem novely zákona o návykových látkách z roku 2021, která zavedla novou definici technického konopí a umožnila udílení dalších licencí pro pěstování léčebného konopí. V rámci poslaneckého mandátu se také věnoval kontrole činnosti GIBS, kde se věnoval případům policejní brutality a zneužití metody pachové identifikace. Jako předseda tzv. Velkého ucha se zabýval dohledem nad odposlechy PČR a Celníků.",
        link: "https://forum.pirati.cz/viewtopic.php?t=68047",
        img: "tomas-vymazal.jpg",
      },
      {
        name: "Lukáš Hejduk",
        desc: "Lukáš Hejduk (* 2. 4. 1994) vystudoval všeobecné gymnázium a tři semestry oboru Právo a právní věda na PrF MUNI. V současné době se živí jako IT developer – vývojář a technický konzultant – a občas stále zabrousí do světa sportovních sázek, kterými se jako profesionální sportovní sázkař a následně jako bookmaker živil dříve. Působí jako trenér basketbalového týmu, který hraje v městských soutěžích v Brně. Ve veřejném prostoru se zajímá o financování sportu, regulaci hazardu či sociální a vzdělávací témata. Aktuálně je členem Republikového výboru strany, dříve působil v Kontrolní komisi, dlouhodobě se věnuje vnitrostranickému dění, podílel se například na aktualizaci stanov či na debatě o reorganizaci strany.",
        link: "https://forum.pirati.cz/viewtopic.php?t=68060",
        img: "lukas-hejduk.jpg",
      },
      {
        name: "Mikuláš Ferjenčík",
        desc: "Mikuláš Ferjenčík (* 19. 3. 1987) vystudoval bakalářské studium na FHS UK, pracoval jako koordinátor dobrovolníků v protikorupční kampani Rekonstrukce státu, překládal a vedl kroužky zábavné logiky. Byl zastupitelem v Praze a v letech 2017 – 2021 poslancem. Nyní pracuje jako asistent předsedy poslaneckého klubu Pirátů Jakuba Michálka. V Poslanecké sněmovně se věnoval především problematice financí z pozice místopředsedy rozpočtového výboru. Prosadil výrazné zvýšení daňové slevy na poplatníka. Každý pracující díky tomu ušetřil 6000 korun ročně, vyjednal zlepšení podmínek pro domácí vaření piva a prosadil kompenzační bonus pro dohodáře.",
        link: "https://forum.pirati.cz/viewtopic.php?t=68063",
        img: "mikulas-ferjencik.jpg",
      },
      {
        name: "Petr Chaluš",
        desc: "Petr Chaluš (* 8. 7. 1973) vystudoval Filozofickou fakultu UK, obor pedagogika. Pracoval jako poradenský pracovník ZŠ Londýnská, dále v Domě zahraniční spolupráce jako metodik v rámci programu Erasmus+.Aktuálně koordinuje české centrum evropské sítě Euroguidance na Národním pedagogickém institutu, kde zároveň pracuje v odborných skupinách ke kariérovému poradenství a k rozvoji kurikula základních a středních škol. Angažuje se v inovacích českého vzdělávání, podílí se na připomínkování školské legislativy, publikuje články, metodicky podporuje a vzdělává pedagogické pracovníky a rodiče. V dizertačním výzkumu na Ústavu výzkumu a rozvoje vzdělávání se věnuje participaci rodičů ve školských radách. Je předsedou organizace Fórum Rodičů, členem rady Sdružení pro kariérové poradenství a kariérový rozvoj.",
        link: "https://forum.pirati.cz/viewtopic.php?t=67901",
        img: "petr-chalus.jpg",
      },
    ],
  },
];
