
import { Helmet } from "react-helmet";

import PageTitle from "components/PageTitle";
import { basics } from "config";

const KidsnFun = () => {
  return (
    <article className="w-full">
      <PageTitle>Děti a zábava pro malé i velké</PageTitle>

      <Helmet>
        <title>Děti a zábava pro malé i velké | {basics.appTitle}</title>
        <meta property="og:title" content="Děti a zábava pro malé i velké" />
      </Helmet>

      <div className="content-block">

<p>
Součástí “CFka” znovu bude také oddělená zábava pro děti.
</p>

<p>
Více informací brzy doplníme...
</p>

{/*
<ul>
    <li>promítání pohádek</li>
    <li>tvořivé dílničky</li>
    <li>zdravá sváča a voda</li>
    <li>klidný kout pro přebalení i nakojení</li>
    <li>přenos z hlavního sálu</li>
</ul>

<p>Pokud se chystáte brát s sebou dítko, dejte nám prosím vědět - v registraci, pro dotazy se obraťte na <a haref="mailto:hana.cernosova@pirati.cz">hana.cernosova@pirati.cz</a></p>
*/}
      </div>
    </article>
  );
};

export default KidsnFun;
