import { Helmet } from "react-helmet";

import PageTitle from "components/PageTitle";
import Button from "components/Button";
// import VenueCard from "components/VenueCard";
// import Badge from "components/Badge";

import { venues, organisers } from "config";
import { basics } from "config";

const Volunteers = () => {
  const venueItem = venues[1];
  const person = organisers[0].members[1];
  return (
    <article className="w-full">
      <PageTitle>Pro dobrovolníky</PageTitle>

      <Helmet>
        <title>Pro dobrovolníky | {basics.appTitle}</title>
        <meta property="og:title" content="Pro dobrovolníky" />
      </Helmet>

      <div className="content-block">
      
        {/*
        <ul>
          <li>
            cca 5 dobrovolníků na registrační místo (sobota 8:00 až do doby
            potřebné k akreditaci členů)
          </li>
          <li>
            cca 2-4 dobrovolníky k zajištění dalších potřebných věcí (příprava
            prostor, běžná agenda)
          </li>
        </ul>
*/}
{/*
        <h3>Na koho se obrátit</h3>
        <p>
          Pokud máš zájem se zapojit, obrať se prosím na koordinátora Ondřeje Kapitančika - 728 863 757, <a href="mailto:ondrej.kapitancik@pirati.cz">ondrej.kapitancik@pirati.cz</a>.
        </p>

        <h3>Jak budu pomáhat?</h3>
        <p>
          Vše je na domluvě a tvých možnostech, i malá pomoc se počítá. Určitě budeš mít prostor si CF užít i jako návštěvník.
        </p>

        <h3>Musím být k dispozici po celou dobu?</h3>
        <p>
          Ne. Budeme rádi za každou pomoc. Největší nápor bude mezi 9:00 až
          10:30 v sobotu na registraci. Pak už to zvládneme v menším počtu.
          Během dne určitě zvládneš pokec s kamarády nebo známými, tak aby sis{" "}
          {basics.appTitle} taky mohl(a) užít.
        </p>

        <h3>Co za to získám?</h3>
        <p>
          Peníze nenabízíme. Získáš dobrý pocit, skvělý zážitek, spoustu nových
          kontaktů a pirátské body. Vhodné také pro zájemce, kteří jsou čekatelé
          na členství u Pirátů a nebo o tom teprve uvažují.
        </p>
        */}
        <p>
          Máš chuť a čas se připojit k organizátorskému týmu následujícího "CFka" a podílet se na přípravě či chodu této akce?</p>
          <p>Zaregistruj se a vyplň co nejvíce podrobných informací. Pokud nám přijde tvá nabídka vhod, ozveme se. Díky!</p>
          <p>&nbsp;</p>
      </div>
 {/*
      <div className="content-block mt-6 mb-2">
        <h3>Adresa jednacího sálu</h3>
      </div>

      <div className="grid md:grid-cols-2 gap-16 content-block">
        <VenueCard
          street={venueItem.street}
          city={venueItem.city}
          gps={venueItem.gps}
          href={venueItem.href}
          title_short={venueItem.title_short}
        />
        <Badge
          name={person.name}
          position={person.position}
          avatarUrl={person.avatarUrl}
          phone={person.phone}
          email={person.email}
          key={person.name}
        />
      </div>
      */}
      <Button
        className="text-lg btn--to-blue-300 mb-2"
        bodyClassName="whitespace-no-wrap"
        color="black"
        href="https://mrak.pirati.cz/apps/forms/PC8qrrQAin3B65zg"
        icon="ico--chevron-right"
        target="_blank"
      >
        Registrační formulář
      </Button>

    </article>
  );
};

export default Volunteers;
